import React, { Component } from 'react'
import {
    withRouter,
    Redirect
} from 'react-router-dom'
import { Container, Row, Col, Button, ProgressBar, Form } from 'react-bootstrap'
import 'babel-polyfill';
import _ from 'lodash'
import { Doughnut, Line } from 'react-chartjs-2';
import moment from 'moment'
import Page from './Page'
import '../styles/global.css'
import NumberHelpers from '../helpers/NumberHelpers'
import Header from '../containers/HeaderContainer';
import DateTimePicker from 'react-datetime-picker'



class EditEvent extends Page {

    state = {
        busy: false,
        data: {},
        checkingUser: true
    }

    componentDidMount() {

        window.scrollTo(0, 0)
        let self = this

        this._fetchEvent = this._fetchEvent.bind(this)
        this._updateEvent = this._updateEvent.bind(this)
        this._drawWinner = this._drawWinner.bind(this)

        if (!this.props.user) {
            let self = this
            this.setState({ checkingUser: true }, () => {
                self.props.checkUserSession(() => {
                    self.setState({ checkingUser: false })
                    self._fetchEvent()
                })
            })
        } else {
            self.setState({ checkingUser: false }, () => {
                self._fetchEvent()
            })

        }


    }

    _fetchEvent() {
        let self = this
        let pathArr = self.props.location.pathname.split("/")
        let clientId = pathArr[pathArr.length - 3]
        let eventId = pathArr[pathArr.length - 2]

        console.log(clientId, eventId)

        self.setState({ busy: true }, () => {
            self.props.fetchClientAndEvent(clientId, eventId, (data) => {

                let drawDate = data.event.drawDate && data.event.drawDate != "" && moment(new Date(data.event.drawDate)).isValid ? new Date(data.event.drawDate) : ""
                data.event.drawDate = drawDate
                console.log("drawDate", drawDate)
                self.setState({ busy: false, data: data, useDrawDate: moment(drawDate).isValid() })
            })
        })
    }

    _updateEvent() {
        let self = this

        if (!self.state.data.event.maxPrizePool || self.state.data.event.maxPrizePool == "" || parseInt(self.state.data.event.maxPrizePool) == 0 || isNaN(self.state.data.event.maxPrizePool)) {
            alert("Please enter a valid max prize pool")
            return
        }

        let drawDate = ""
        if (moment(self.state.data.event.drawDate).isValid()) {
            console.log(self.state.data.event.drawDate)
            drawDate = moment(self.state.data.event.drawDate).toDate().toISOString()
        }

        let data = {
            clientId: self.state.data.client.PK,
            eventId: self.state.data.event.SK,
            maxPrizePool: parseInt(self.state.data.event.maxPrizePool),
            drawDate: drawDate
        }
        console.log(data)

        self.setState({ busy: true }, () => {
            self.props.updateMaxPrizePoolAndDrawDate(data, () => {
                self.setState({ busy: false }, () => {
                    self._nagivateToPage("/events/" + self.state.data.client.PK)
                })

            })
        })

    }

    _drawWinner() {
        let self = this

        if (window.confirm("Are you sure you want to draw this event?")) {
            
            let data = {
                clientId: self.state.data.client.PK,
                eventId: self.state.data.event.SK
            }
            
            self.setState({ busy: true }, () => {
                self.props.manualDraw(data, () => {
                    self.setState({ busy: false }, () => {
                        self._nagivateToPage("/events/" + self.state.data.client.PK)
                    })
    
                })
            })
        }
    }

    render() {
        let self = this

        if (self.state.checkingUser || self.state.busy) {
            return (
                <div className="wrapper">
                    <div className="container-fluid">
                        <div className="text-center v-margin-20">
                            <img src="/images/loaders/oval.svg" />
                        </div>
                    </div>
                </div>
            )
        }

        if (!this.props.user) {
            return <Redirect to='/events/' />
        }

        if (!self.state.data.event || !self.state.data.client) {
            return (
                <Container>
                    <div className="text-center v-margin-20">
                        Event not found.
                    </div>
                </Container>
            )
        }
        let eventLink = "/events/" + self.state.data.event.PK
        let content =
            <div className="wrapper">
                <div className="container-fluid">
                    <a href="/">Dashboard</a> > <a href={eventLink}>{self.state.data.client.name}</a> > Edit ({self.state.data.event.SK})
                </div>
                <div className="container-fluid">
                    <h4>Edit Event</h4>
                    <div className="formInput">
                        <strong>Max Prize Pool</strong>(integer, no decimals)<br /><Form.Control disabled={self.state.busy} type="number" placeholder="Max Prize Pool" value={self.state.data.event.maxPrizePool} onChange={(e) => {
                            let d = self.state.data
                            d.event.maxPrizePool = e.target.value
                            self.setState({ data: d })
                        }} />
                    </div>
                    <div className="formInput">
                        <strong>Draw Date</strong><br />
                        <Form.Check type="checkbox" label="Set Draw Date"
                            onChange={(e) => {
                                console.log(e.target)
                                let d = self.state.data
                                d.event.drawDate = e.target.checked ? new Date() : ""
                                self.setState({ data: d, useDrawDate: e.target.checked })
                            }
                            } checked={self.state.useDrawDate} /><br />
                        {self.state.useDrawDate ?
                            <DateTimePicker
                                disableClock={true}
                                value={self.state.data.event.drawDate} onChange={(v) => {
                                    let d = self.state.data
                                    d.event.drawDate = v
                                    self.setState({ data: d })
                                }} />
                            : null
                        }
                    </div>
                    <div className="formInput">
                        <Button
                            onClick={() => {
                                this._updateEvent()
                            }}
                        >
                            Save
                      </Button>
                    </div>
                    {!self.state.data.client.rolling ?
                        <div className="formInput">
                            <hr />
                            <Button
                                onClick={() => {
                                    this._drawWinner()
                                }}
                                variant="danger"
                            >
                                Manually Draw Winner
                      </Button>
                        </div>
                        : null}
                </div>
            </div>

        return (
            <div>
                <Header />
                {content}
            </div>
        )
    }
}

export default withRouter(EditEvent)        