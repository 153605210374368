import { connect } from 'react-redux'
import Promo from '../components/Promo'
import * as api from '../store/api'

const mapStateToProps = (state) => {
  return {
    user:state.service.user
  };
}


const mapDispatchToProps = (dispatch) => ({
  checkUserSession: (callback) => { dispatch(api.checkUserSession()).then(callback) },
  fetchEventReport: (clientId, eventId, callback) => {
    dispatch(api.fetchEventReport(clientId, eventId)).then(callback)
  }
})


export default connect(mapStateToProps, mapDispatchToProps)(Promo)
