import 'babel-polyfill'

import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { loadingBarMiddleware } from 'react-redux-loading-bar'

import rootReducer from './store/reducers';
import 'bootstrap/dist/css/bootstrap.css'

import './assets/scss/DefaultTheme.scss';


import {
    BrowserRouter as Router,
    Route,
    Switch
} from 'react-router-dom'

import Login from './containers/LoginContainer';
import Dashboard from './containers/DashboardContainer';
import Events from './containers/EventsContainer';
import Report from './containers/ReportContainer';
import ReportPDF from './containers/ReportPDFContainer';
import EditEvent from './containers/EditEventContainer';
import Promo from './containers/PromoContainer';



const loggerMiddleware = createLogger()

const store = createStore(
    rootReducer,
    applyMiddleware(
        thunkMiddleware,
        loggerMiddleware,
        loadingBarMiddleware())
)


ReactDOM.render(

    <Provider store={store}>

        <Router>
            <Switch>
                <Route exact path="/" component={Login} />
                <Route exact path="/clients" component={Dashboard} />
                <Route exact path="/events/*/*/edit" component={EditEvent} />
                <Route exact path="/events/*/*" component={Report} />                
                <Route exact path="/pdf/*" component={ReportPDF} />                
                <Route exact path="/events/*" component={Events} />
                <Route exact path="/promo" component={Promo} />
                
            </Switch>
        </Router>
    </Provider>
    ,

    document.getElementById('root')
);
