import React, { Component } from 'react'
import {
    withRouter,
    Redirect
} from 'react-router-dom'
import { Container, Row, Col, Button, ProgressBar, Badge } from 'react-bootstrap'
import 'babel-polyfill';
import _ from 'lodash'

import Page from './Page'
import '../styles/global.css'
import Header from '../containers/HeaderContainer'

class Events extends Page {

    state = {
        busy: false,
        clientId: -1,
        checkingUser: true,
        noLiveEvents: false
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        let self = this
        this._fetchEvents = this._fetchEvents.bind(this)
        this._newEvent = this._newEvent.bind(this)
        if (!this.props.user) {
            let self = this
            this.setState({ checkingUser: true }, () => {
                self.props.checkUserSession(() => {
                    self.setState({ checkingUser: false })
                    self._fetchEvents()
                })
            })
        } else {
            self.setState({ checkingUser: false }, () => {
                self._fetchEvents()
            })

        }



    }

    _fetchEvents() {
        let self = this
        let pathArr = self.props.location.pathname.split("/")
        let clientId = pathArr[pathArr.length - 1]
        self.setState({ clientId: clientId, busy: true }, () => {
            let noLiveEvents = true
            self.props.fetchEvents(clientId, () => {
                _.forEach(self.props.events, (evt) => {
                    if (!evt.completed) noLiveEvents = false
                })
                self.setState({ busy: false, noLiveEvents: noLiveEvents })
            })
        })
    }

    _newEvent() {
        let self = this

        if (window.confirm("Are you sure you want to create a new event?")) {

            let data = {
                clientId: self.state.clientId
            }

            self.setState({ busy: true }, () => {
                self.props.createNextEvent(data, (newEvent) => {
                    console.log(newEvent)
                    if (newEvent.error) {
                        alert(newEvent.message)
                        self.setState({busy:false})
                    } else {
                        self._fetchEvents()
                    }

                })
            })
        }
    }

    render() {
        let self = this

        if (self.state.checkingUser || self.state.busy) {
            return (
                <div className="wrapper">
                    <div className="container-fluid">
                        <div className="text-center v-margin-20">
                            <img src="/images/loaders/oval.svg" />
                        </div>
                    </div>
                </div>
            )
        }

        if (!this.props.user) {
            return <Redirect to='/' />
        }

        let eventsList = []
        if (this.props.events) {
            for (let i = 0; i < this.props.events.length; i++) {
                let link = "/events/" + self.state.clientId + "/" + self.props.events[i].SK
                let edit = "/events/" + self.state.clientId + "/" + self.props.events[i].SK + "/edit"
                eventsList.push(
                    <div class="col-xl-3 col-md-6">
                        <div class="widget-simple text-center card-box">
                            <h3 class="text-success counter mt-0">${self.props.events[i].prizePool}</h3>
                            {self.props.events[i].completed ? <Badge variant="danger">COMPLETED</Badge> : <Badge variant="success">LIVE</Badge>}
                            <p class="text-muted mb-0">{self.props.events[i].SK}</p>
                            {!self.props.events[i].completed ? <div><Button href={edit} variant="warning" size="sm">Manage</Button><br /></div> : null}
                            <Button href={link} variant="info" size="sm">View Report</Button>
                        </div>
                    </div>
                )
            }
        }

        let content =
            <div className="wrapper">
                <div className="container-fluid">
                    <a href="/">Dashboard</a> > {self.props.client ? self.props.client.name : null}
                    <h3>{self.props.client ? "Events for " + self.props.client.name : null}</h3>
                    {self.state.noLiveEvents ?
                        <Button
                            style={{ marginBottom: 20 }}
                            onClick={() => {
                                this._newEvent()
                            }}
                        >
                            Create New Event
              </Button>
                        : null}
                    <Row>
                        {eventsList}
                    </Row>
                </div>
            </div>


        return (
            <div>
                <Header />
                {content}
            </div>

        )
    }
}

export default withRouter(Events)        