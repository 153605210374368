import { connect } from 'react-redux'
import EditEvent from '../components/EditEvent'
import * as api from '../store/api'

const mapStateToProps = (state) => {
    return {
        user: state.service.user
    };
}


const mapDispatchToProps = (dispatch) => ({
    checkUserSession: (callback) => { dispatch(api.checkUserSession()).then(callback) },
    fetchClientAndEvent: (clientId, eventId, callback) => {
        dispatch(api.fetchClientAndEvent(clientId, eventId)).then(callback)
    },
    updateMaxPrizePoolAndDrawDate: (data, callback) => {
        dispatch(api.updateMaxPrizePoolAndDrawDate(data)).then(callback)
    },
    manualDraw: (data, callback) => {
        dispatch(api.manualDraw(data)).then(callback)
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(EditEvent)
