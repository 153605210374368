
import { connect } from 'react-redux';

import Header from '../components/Header';
import * as api from '../store/api'


const mapStateToProps = (state) => {
    return {
        apiStatus: state.service.apiStatus,
        user: state.service.user,
    };
}


const mapDispatchToProps = (dispatch) => ({
    logout: (callback) => { dispatch(api.signOut()).then(callback) }
})


export default connect(mapStateToProps, mapDispatchToProps)(Header)