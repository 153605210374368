import React, { Component } from 'react'
import {
    withRouter,
    Redirect
} from 'react-router-dom'
import { Container, Row, Col, Button, ProgressBar } from 'react-bootstrap'
import 'babel-polyfill';
import _ from 'lodash'
import { Doughnut, Line } from 'react-chartjs-2';
import moment from 'moment-timezone'
import Page from './Page'
import '../styles/global.css'
import NumberHelpers from '../helpers/NumberHelpers'
import Header from '../containers/HeaderContainer';

const bundleSaleColours = [
    "red",
    "blue",
    "green",
    "orange",
    "yellow",
    "pink",
    "purple",
    "black"
]

class ReportPDF extends Page {

    state = {
        busy: false,
        data: null,
        checkingUser: true,
        winnersName: "",
        attendance: ""
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        let self = this

        self._populate = self._populate.bind(self)

        this._populate()

        document.body.style.background = '#ffffff';


    }

    _populate() {
        let self = this

        /* let clientId = "Z2xlbmVsZ2Zj"
        let eventId = "Z2xlbmVsZ2Zj_001"
        self.setState({ busy: true }, () => {
            self.props.fetchEventReport(clientId, eventId, (data) => {
                console.log(data)
                data.winner = { phone: 123 }
                data.firstTicketSold =data.event.startDate
                data.lastTicketSold = data.event.endDate
                self.setState({ busy: false, data: data, event: data.event, winnersName: "Joe Blogss", attendance: 2532, rate: 0.1 })
            })
        }) */

        let dataBase64 = self.props.location.search.split("?data=")[1]
        console.log(dataBase64)
        let dataString = atob(dataBase64)
        let data = JSON.parse(dataString)
        self.setState({ data, event: data.event, winnersName: data.winnersName, attendance: data.attendance, rate: data.rate })


    }

    render() {
        let self = this

        if (self.state.busy || !self.state.data) {
            return (
                <div className="wrapper">
                    <div className="container-fluid">
                        <div className="text-center v-margin-20">
                            <img src="/images/loaders/oval.svg" />
                        </div>
                    </div>
                </div>
            )
        }

        let winner = this.state.data.event.completed ? {} : null

        let purchasesDict = {}
        var m1 = moment(new Date(self.state.data.event.startDate));
        var roundDown1 = m1.startOf('date');
        var m2 = moment(self.state.data.event.completed ? new Date(self.state.data.event.endDate) : new Date());
        var roundDown2 = m2.startOf('date');
        var a = moment(roundDown1);
        var b = moment(roundDown2);
        let diff = Math.abs(a.diff(b, 'days'))
        for (let k = 0; k < diff; k++) {
            let n = moment(a);
            n.add(k, "days")
            purchasesDict[n] = 0
        }


        let duration = moment.duration(moment(self.state.data.event.endDate).diff(moment(self.state.data.event.startDate)))
        //Get Days and subtract from duration
        var days = Math.abs(Math.round(duration.asDays()))

        duration.subtract(moment.duration(days, 'days'))

        //Get hours and subtract from duration
        var hours = Math.abs(Math.round(duration.hours()))
        duration.subtract(moment.duration(hours, 'hours'))

        //Get Minutes and subtract from duration
        var minutes = Math.abs(Math.round(duration.minutes()))
        duration.subtract(moment.duration(minutes, 'minutes'))

        let durationAsString = days + " days, " + hours + " hours, " + minutes + " mins"

        let activeDuration = moment.duration(moment(self.state.data.firstTicketSold).diff(moment(self.state.data.lastTicketSold)))
        //Get Days and subtract from duration
        var activeDays = Math.abs(Math.round(activeDuration.asDays()))

        activeDuration.subtract(moment.duration(activeDays, 'days'))

        //Get hours and subtract from duration
        var activeHours = Math.abs(Math.round(activeDuration.hours()))
        activeDuration.subtract(moment.duration(activeHours, 'hours'))

        //Get Minutes and subtract from duration
        var activeMinutes = Math.abs(Math.round(activeDuration.minutes()))
        activeDuration.subtract(moment.duration(activeMinutes, 'minutes'))

        let activeDurationAsString = activeDays + " days, " + activeHours + " hours, " + activeMinutes + " mins"


        let widgets = []

        widgets.push(
            <Row>
                <Col md={4}>
                    <div class="widget-simple text-center card-box">
                        <h3 class="text-success counter mt-0">${self.state.data.event.prizePool}</h3>
                        <p class="text-muted mb-0">Prize Pool</p>
                    </div>
                </Col>
                <Col md={4}>
                    <div class="widget-simple text-center card-box">
                        <h3 class="text-success counter mt-0">{self.state.data.event.ticketCount}</h3>
                        <p class="text-muted mb-0">Tickets Sold</p>
                    </div>
                </Col>
                <Col md={4}>
                    <div class="widget-simple text-center card-box">
                        <h3 class="text-success counter mt-0">{self.state.attendance}</h3>
                        <p class="text-muted mb-0">Game Day Attendance</p>
                    </div>
                </Col>
            </Row>
        )
        widgets.push(
            <Row>
                <Col md={4}>
                    <div class="widget-simple text-center card-box">
                        <h3 class="text-success counter mt-0">${(self.state.data.event.prizePool * 0.5).toFixed(2)}</h3>
                        <p class="text-muted mb-0">Amount to Winner</p>
                    </div>
                </Col>
                <Col md={4}>
                    <div class="widget-simple text-center card-box">
                        <h3 class="text-success counter mt-0">${((self.state.data.event.prizePool / 2) - ((self.state.data.event.prizePool) * (self.state.rate))).toFixed(2)}</h3>
                        <p class="text-muted mb-0">Amount to {self.state.data.client.name}</p>
                    </div>
                </Col>
                <Col md={4}>
                    <div class="widget-simple text-center card-box">
                        <h3 class="text-success counter mt-0">${(self.state.data.event.prizePool * self.state.rate).toFixed(2)}</h3>
                        <p class="text-muted mb-0">Amount to Split 50-50</p>
                    </div>
                </Col>


            </Row>
        )
        widgets.push(
            <Row>
                <Col md={4}>
                    <div class="widget-simple text-center card-box">
                        <h3 class="text-success counter mt-0">{self.state.data.event.SK + "_" + NumberHelpers.pad(self.state.data.event.winningTicket, 3)}</h3>
                        <p class="text-muted mb-0">Winning Ticket</p>
                    </div>
                </Col>
                <Col md={4}>
                    <div class="widget-simple text-center card-box">
                        <h3 class="text-success counter mt-0">{self.state.winnersName}</h3>
                        <p class="text-muted mb-0">Winner's Name</p>
                    </div>
                </Col>
                <Col md={4}>
                    <div class="widget-simple text-center card-box">
                        <h3 class="text-success counter mt-0">{self.state.data.winner.phone}</h3>
                        <p class="text-muted mb-0">Winning Phone Number</p>
                    </div>
                </Col>



            </Row>
        )
        widgets.push(
            <Row>
                <Col md={4}>
                    <div class="widget-simple text-center card-box">
                        <h3 class="text-success counter mt-0">{moment(self.state.data.event.startDate).tz("Australia/Adelaide").format("DD/MM/YYYY h:mmA")}</h3>
                        <p class="text-muted mb-0">Start Date</p>
                    </div>
                </Col>
                <Col md={4}>
                    <div class="widget-simple text-center card-box">
                        <h3 class="text-success counter mt-0">{moment(self.state.data.event.endDate).tz("Australia/Adelaide").format("DD/MM/YYYY h:mmA")}</h3>
                        <p class="text-muted mb-0">Draw Date</p>
                    </div>
                </Col>
                <Col md={4}>
                    <div class="widget-simple text-center card-box">
                        <h3 class="text-success counter mt-0">{durationAsString}</h3>
                        <p class="text-muted mb-0">Duration</p>
                    </div>
                </Col>



            </Row>
        )
        
        widgets.push(
            <Row>
                <Col md={4}>
                    <div class="widget-simple text-center card-box">
                        <h3 class="text-success counter mt-0">{moment(self.state.data.firstTicketSold).tz("Australia/Adelaide").format("DD/MM/YYYY h:mmA")}</h3>
                        <p class="text-muted mb-0">First ticket sold</p>
                    </div>
                </Col>
                <Col md={4}>
                    <div class="widget-simple text-center card-box">
                        <h3 class="text-success counter mt-0">{moment(self.state.data.lastTicketSold).tz("Australia/Adelaide").format("DD/MM/YYYY h:mmA")}</h3>
                        <p class="text-muted mb-0">Last ticket sold</p>
                    </div>
                </Col>
                <Col md={4}>
                    <div class="widget-simple text-center card-box">
                        <h3 class="text-success counter mt-0">{activeDurationAsString}</h3>
                        <p class="text-muted mb-0">Active Duration</p>
                    </div>
                </Col>



            </Row>
        )

        let eventLink = "/events/" + self.state.data.event.PK
        let content =
            <div className="reportWrapper">
                <div className="topStrip" style={{ background: self.state.data.client.styles.background }}>
                    <div className="logo">
                        <img src="/images/logo.png" alt="" height="40" />
                        <img src={self.state.data.client.styles.logo} alt="" height="80" />
                    </div>
                </div>
                <div className="reportContainer">
                    <div className="reportTopBg">
                        {widgets}
                    </div>
                    <div className="reportBottomBg">
                        <Row>
                            <Col md={12}>
                                <h3>Sale Trends</h3>
                            </Col>
                            <Col md={12}>
                                <Line height={80} data={{
                                    labels: self.state.data.salesLabels,
                                    datasets: [{
                                        label: "Sales",
                                        data: self.state.data.salesData
                                    }
                                    ]
                                }}
                                    options={
                                        {
                                            scales: {
                                                yAxes: [{
                                                    ticks: {
                                                        beginAtZero: true,
                                                        fontSize: 18
                                                    }
                                                }],
                                                xAxes: [{
                                                    ticks: {
                                                        fontSize: 18
                                                    }
                                                }]
                                            },
                                        }
                                    }
                                />
                            </Col>
                            <Col md={12}>
                                <Doughnut height={100} data={{
                                    datasets: [{
                                        data: self.state.data.bundleSaleAmounts,
                                        backgroundColor: bundleSaleColours
                                    }],

                                    // These labels appear in the legend and in the tooltips when hovering different arcs
                                    labels: self.state.data.bundleSaleLabels,

                                }}
                                    legend={{
                                        labels: {
                                            fontSize: 18
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>


        return (
            <page className="reportPDF" size="A4">
                {content}
            </page>
        )
    }
}

export default withRouter(ReportPDF)        