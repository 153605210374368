import React, { Component } from 'react'
import {
    withRouter,
    Redirect
} from 'react-router-dom'
import { Container, Row, Col, Button, ProgressBar } from 'react-bootstrap'
import 'babel-polyfill';
import _ from 'lodash'

import Page from './Page'
import '../assets/libs/custombox/custombox.min.css'
import '../assets/css/app.css'
import '../assets/css/icons.css'
import Header from '../containers/HeaderContainer';

const TIMER = 10

class Dashboard extends Page {

    state = {
        busy: false,
        checkingUser: true,
        generatingPdf:false
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        let self = this

        this._fetchClients = this._fetchClients.bind(this)

        if (!this.props.user) {
            let self = this
            this.setState({ checkingUser: true }, () => {
                self.props.checkUserSession(() => {
                    self.setState({ checkingUser: false })
                    self._fetchClients()
                })
            })
        } else {
            self.setState({ checkingUser: false }, () => {
                self._fetchClients()
            })
        }



    }

    _fetchClients() {
        let self = this
        self.setState({ busy: true }, () => {
            self.props.fetchClients(() => {
                self.setState({ busy: false })
            })
        })
    }

    render() {
        let self = this

        if (self.state.checkingUser || self.state.busy) {
            return (
                <div className="wrapper">
                    <div className="container-fluid">
                        <div className="text-center v-margin-20">
                            <img src="/images/loaders/oval.svg" />
                        </div>
                    </div>
                </div>
            )
        }

        if (!this.props.user) {
            return <Redirect to='/' />
        }

        let clientList = []
        if (this.props.clients) {
            for (let i = 0; i < this.props.clients.length; i++) {
                let link = "/events/" + self.props.clients[i].PK
                let stub = "https://s50.events/" + self.props.clients[i].stub

                clientList.push(
                    <Col md={4}>

                        <div className="text-center card-box ribbon-box" style={{ background: self.props.clients[i].styles.background }}>
                            <div className="clearfix"></div>
                            <div className="pt-2 pb-2">
                                <img src={self.props.clients[i].styles.logo} style={{ height: 100, maxHeight: 100 }} className="" alt="profile-image" />

                                <p className="text-muted">{self.props.clients[i].charity} <span><br /></span> <span> <a href={stub} target="_blank" className="text-pink">{stub}</a> </span></p>

                                <Button disabled={self.state.generatingPdf} href={link} type="button" className="btn btn-primary btn-sm waves-effect waves-light" style={{ marginRight: 10 }}>View Events</Button>
                                <Button disabled={self.state.generatingPdf} type="button" className="btn btn-primary btn-sm waves-effect waves-light" style={{ marginLeft: 10 }} onClick={() => {

                                    let base64 = btoa(JSON.stringify(self.props.clients[i]))

                                    let path = "https://admin.s50.events/promo/?client=" + base64


                                    self.setState({ generatingPdf: true }, () => {
                                        self.props.generatePDF(path, (res) => {
                                            console.log(res)
                                            if (res.url) {
                                                var element = document.createElement('a');
                                                element.setAttribute('href', res.url);
                                                element.setAttribute('download', self.state.eventId + ".pdf");
                                                element.setAttribute('target', "_blank");

                                                element.style.display = 'none';
                                                document.body.appendChild(element);

                                                element.click();

                                                document.body.removeChild(element);
                                            }
                                            self.setState({ generatingPdf: false })
                                        })
                                    })

                                }
                            }>{self.state.generatingPdf ? "Generating..." : "Genereate QR"}</Button>

                            </div>
                        </div>
                    </Col>
                )
            }
        }

        let content =
            <div>
                <ul>
                    {clientList}
                </ul>
            </div>


        return (

            <div>
                <Header />
                <div className="wrapper">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box">
                                    <h4 className="page-title">Clients</h4>
                                </div>
                                <Row>
                                    {clientList}
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        )
    }
}

export default withRouter(Dashboard)

// styling
// auth
// deploy≈