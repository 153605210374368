import React, { Component } from 'react'
import {
    withRouter
} from 'react-router-dom'
import { Container, Row, Col, Button } from 'react-bootstrap'
import 'babel-polyfill';
import _ from 'lodash'
import moment from 'moment'

import '../styles/global.css'


export default class Page extends Component {

    state = {

    }
    
    componentDidMount() {
        
    }

    _nagivateToPage(path) {
        this.props.history.push(path)
    }
}
