import React from "react";
import { Redirect } from 'react-router'
import { Container, Row, Col, Button, ProgressBar, Badge } from 'react-bootstrap'
import Header from "../containers/HeaderContainer";


class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkingUser:false
        };
    }

    componentDidMount() {
        const ga = window.gapi && window.gapi.auth2 ?
            window.gapi.auth2.getAuthInstance() :
            null;
        if (!ga) this._createScript();

        let self = this
        this.setState({checkingUser:true}, () => {
            self.props.checkUserSession(() => {
                self.setState({checkingUser:false})
            })
        })
    }

    _createScript() {
        // load the Google SDK
        const script = document.createElement('script');
        script.src = 'https://apis.google.com/js/platform.js';
        script.async = true;
        script.onload = this._initGapi;
        document.body.appendChild(script);
    }

    _initGapi() {
        // init the Google SDK client
        const g = window.gapi;
        g.load('auth2', function () {
            g.auth2.init({
                client_id: '264938716570-2rmeiaf0081it3n3ktlg8qvs6dk5nu9v.apps.googleusercontent.com',
                // authorized scopes
                scope: 'profile email openid'
            });
        });
    }

    _signInWithGoogle() {
        let self = this
        const ga = window.gapi.auth2.getAuthInstance();
        ga.signIn().then(
            googleUser => {
                self.props.signInWithGoogle(googleUser)
            },
            error => {
                console.log(error);
            }
        );
    }

    render() {

        var contentBlock = null

        console.log(this.state.checkingUser, this.props.apiStatus)
        if (this.state.checkingUser || this.props.apiStatus != "API_IDLE") {
            contentBlock =
                <div>
                    Please wait...
        </div>
        } else if (!this.props.user) {
            contentBlock =
                <div>

                    <Button
                        onClick={() => {
                            this._signInWithGoogle()
                        }}
                        size="lg"
                    >
                        Sign In with Google
                      </Button>

                      <hr />

                    
                </div>

        } else {
            return <Redirect to='/clients' />;
        }

        return (
            <div><Header />
            <div className="wrapper">
                <div className="container-fluid">
                    {contentBlock}
                </div>
            </div>
            </div>
        );
    }
}

export default LoginPage;
