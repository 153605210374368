import { connect } from 'react-redux'
import Dashboard from '../components/Dashboard'
import * as api from '../store/api'

const mapStateToProps = (state) => {
  return {
    clients:state.service.clients,
    user:state.service.user,
  };
}


const mapDispatchToProps = (dispatch) => ({
  checkUserSession: (callback) => { dispatch(api.checkUserSession()).then(callback) },
  fetchClients: (callback) => {
    dispatch(api.fetchClients()).then(callback)
  },
  generatePDF: (data, callback) => {
    dispatch(api.generatePDF(data)).then(callback)
  }
})


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
