import React, { Component } from 'react'
import {
    withRouter,
    Redirect
} from 'react-router-dom'
import { Container, Row, Col, Button, ProgressBar } from 'react-bootstrap'
import 'babel-polyfill';
import _ from 'lodash'
import { Doughnut, Line } from 'react-chartjs-2';
import moment from 'moment-timezone'
import Page from './Page'
import '../styles/global.css'
import NumberHelpers from '../helpers/NumberHelpers'
import Header from '../containers/HeaderContainer';
import QRCode from "qrcode.react";

class Promo extends Page {

    state = {
        busy: false,
        client: null,
        checkingUser: true,
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        let self = this

        self._populate = self._populate.bind(self)

        this._populate()

        document.body.style.background = '#ffffff';


    }

    _populate() {
        let self = this
        let dataBase64 = self.props.location.search.split("?client=")[1]
        console.log(dataBase64)
        let dataString = atob(dataBase64)
        let client = JSON.parse(dataString)
        self.setState({ client })



    }

    render() {
        let self = this
        let { client } = self.state

        if (self.state.busy || !client) {
            return (
                <div className="wrapper">
                    <div className="container-fluid">
                        <div className="text-center v-margin-20">
                            <img src="/images/loaders/oval.svg" />
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <page className="reportPDF" size="A4">

                <div className="text-center card-box ribbon-box" style={{ background: client.styles.background, padding:100 }}>
                    <div className="clearfix"></div>
                    <div className="pt-2 pb-2">
                        <img src={client.styles.logo} style={{ height: 200, maxHeight: 200 }} className="" alt="profile-image" />
                        <h1 style={{color:"#ffffff", marginTop:50}}>{client.charity} <span><br /></span></h1>
                        <h3 style={{color:"#ffffff", fontSize:18, marginTop:40}}>Powered by <img src="/images/logo.png" style={{height:20}} /></h3>
                    </div>
                    
                </div>

                <div style={{padding:100, textAlign:"center"}}>
                        
                        <QRCode
                        size={300}
                        value={"https://s50.events/" + client.stub}
                        level="H"
                        imageSettings= {{
                            src:"/images/qr-logo.png",
                            width:80,
                            height:80
                        }}
                        />
                        <h3 style={{width:200, marginLeft:"auto",marginRight:"auto", marginTop:100}}>To buy a ticket scan the QR code above or go to <span style={{fontWeight:"bold"}}>s50.events/{client.stub}</span></h3>
                    </div>

            </page>
        )
    }
}

export default withRouter(Promo)        