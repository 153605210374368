
import { connect } from 'react-redux';

import Login from '../components/Login';
import * as api from '../store/api'


const mapStateToProps = (state) => {
    return {
        apiStatus: state.service.apiStatus,
        user: state.service.user,
    };
}


const mapDispatchToProps = (dispatch) => ({
    checkUserSession: (callback) => { dispatch(api.checkUserSession()).then(callback) },
    signInWithGoogle: (googleUser) => { dispatch(api.signInWithGoogle(googleUser)) }
})


export default connect(mapStateToProps, mapDispatchToProps)(Login)