
const initialState = {
    apiStatus: "API_IDLE",

    // Cognito
    checkedUserSession: false,
    user: null,

    // Data
    client: null,
    event: null,
    clients: null,
    events: null
}

const service = (state = initialState, action) => {
    switch (action.type) {
        case 'API_SAVING':
        case 'API_LOADING':
        case 'API_IDLE':
            return { ...state, apiStatus: action.type };
        case 'SIGN_IN':
            return { ...state, apiStatus: "API_IDLE", user: action.data };
        case 'SIGN_OUT':
            return { ...state, apiStatus: "API_IDLE", user: null, checkedUserSession: false };
        case 'CHECK_USER_SESSION':
            return { ...state, apiStatus: "API_IDLE", user: action.data, checkedUserSession: true };
        case "FETCH_EVENT":
            let client = action.data ? action.data.client : null
            let event = action.data ? action.data.event : null
            return { ...state, client: client, event: event }
        case "FETCH_CLIENTS":
            return { ...state, clients: action.data, event: event }
        case "FETCH_PAST_EVENTS":
            console.log(action.data)
            return { ...state, events: action.data.events, client: action.data.client, event: event }
        default:
            return state;
    }
}

export default service
