import _ from 'lodash';
import https from 'https'
import aws4 from "aws4"
import Amplify from 'aws-amplify'
import Auth from '@aws-amplify/auth'
import Cache from '@aws-amplify/cache'


const API_ENDPOINT = "ay4op9wl42.execute-api.ap-southeast-2.amazonaws.com"
const API_STAGE = "/Prod/"
const API_PROTOCOL = "https://"
const API_REGION = "ap-southeast-2"

const isLocal = window.location.href.indexOf("sandbox") > -1
var currentUser = null

// AWS Amplify
Amplify.configure({
    Auth: {

        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: 'ap-southeast-2:ddc1d0e8-a9ba-4f01-8201-5780cdf5a60a',

        // REQUIRED - Amazon Cognito Region
        region: API_REGION,

        // OPTIONAL - Amazon Cognito Federated Identity Pool Region 
        // Required only if it's different from Amazon Cognito Region
        //identityPoolRegion: 'ap-southeast-2',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'ap-southeast-2_az26TYA9k',

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '4nd8tn9kkp3n4nust87f5tu1f8',

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,

        // OPTIONAL - Configuration for cookie storage
        cookieStorage: {
            // REQUIRED - Cookie domain (only required if cookieStorage is provided)
            domain: isLocal ? 'sandbox.s50.events' : 'admin.s50.events',
            //domain: 'localhost',
            // OPTIONAL - Cookie path
            path: '/',
            // OPTIONAL - Cookie expiration in days
            expires: 30,
            // OPTIONAL - Cookie secure flag
            secure: false
        },

        // OPTIONAL - customized storage object
        //storage: new MyStorage(),

        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        //authenticationFlowType: 'USER_PASSWORD_AUTH'
    }
});

function parseResponse(res) {

    var promise = new Promise((resolve, reject) => {

        var parsedData = null
        var errorMsg = ""

        res.setEncoding('utf8');
        let rawData = '';
        res.on('data', (chunk) => { rawData += chunk; });
        res.on('end', () => {
            try {
                parsedData = JSON.parse(rawData);

            } catch (e) {
                errorMsg = e.message
            }
            if (parsedData) {
                resolve(parsedData)
            } else {
                resolve({
                    error: errorMsg
                })
            }
        });
    });

    return promise

}


function onTest(data) {

    return {
        type: 'TEST',
        data: data,
        receivedAt: Date.now()
    }
}

export function test(data) {
    return function (dispatch) {
        let val = data;
        dispatch(onTest(val))
    }
}

export function testAsync() {
    return function (dispatch) {
        return new Promise((resolve, reject) => {
            setTimeout(function () {
                let val = Math.random();
                dispatch(onTest(val))
                resolve();
            }, 1500)


        })
    }
}

// Signin
function onSignIn(data) {
    return {
        type: 'SIGN_IN',
        data: data,
        receivedAt: Date.now()
    }
}

function onSignOut() {
    return {
        type: 'SIGN_OUT',
        data: null,
        receivedAt: Date.now()
    }
}

export function signOut() {
    return function (dispatch) {
        dispatch({ type: 'API_LOADING' })
        return new Promise((resolve, reject) => {
            Auth.signOut().
                then((user) => {
                    currentUser = null
                    dispatch(onSignOut())
                    resolve()
                })
                .catch((err) => {
                    currentUser = null
                    console.log(err)
                    dispatch(onSignOut())
                    resolve()
                }
                );

        })


    }
}

function onCheckUserSession(data) {
    return {
        type: 'CHECK_USER_SESSION',
        data: data,
        receivedAt: Date.now()
    }
}

export function checkUserSession() {
    return function (dispatch) {
        dispatch({ type: 'API_LOADING' })
        return new Promise(async (resolve, reject) => {

            if (currentUser) {
                dispatch(onCheckUserSession(currentUser))
                resolve()
            } else {
                try {
                Auth.currentAuthenticatedUser()
                    .then(async (data) => {
                        console.log("has user")
                        currentUser = data
                        dispatch(onCheckUserSession(currentUser))
                        resolve()
                    })
                    .catch((err) => {
                        console.log("ERROR", err)
                        dispatch(onCheckUserSession(null))
                        resolve()
                    });
                }catch(e) {
                    console.log("Auth Error", e)
                }
            }
        })
    }
}

export function signInWithGoogle(googleUser) {
    return async function (dispatch) {
        dispatch({ type: 'API_LOADING' })
        console.log("Step 1")
        const { id_token, expires_at } = googleUser.getAuthResponse();
        console.log("Step 2")
        const profile = googleUser.getBasicProfile();
        console.log("Step 3")
        let user = {
            email: profile.getEmail(),
            name: profile.getName()
        };
        console.log("Step 4")
        const federatedUser = await Auth.federatedSignIn(
            'google',
            { token: id_token, expires_at },
            user
        );
        console.log('federatedUser', federatedUser);
        currentUser = federatedUser
        const federatedInfo = Cache.getItem('federatedInfo');
        console.log("federatedInfo", federatedInfo)
        dispatch(onSignIn(currentUser))
    }
}

function onFetchEvent(data) {

    return {
        type: 'FETCH_EVENT',
        data: data,
        receivedAt: Date.now()
    }
}

export function fetchEvent(clientId) {
    clientId = clientId.split("/")[1];

    return function (dispatch) {
        return new Promise(async (resolve, reject) => {

            // get client by stub
            const res = await fetch(API_PROTOCOL + API_ENDPOINT + API_STAGE + "getlatestevent?stub=" + encodeURIComponent(clientId))
            if (!res || !res.ok) {
                console.log("error with fetchEvent", res)
                dispatch(onFetchEvent(null))
                resolve(false);
            } else {
                console.log("res", res)

                const data = await res.json()
                if (data && data.hasEvents) {
                    dispatch(onFetchEvent(data))
                    resolve(data.hasEvents);
                } else {
                    dispatch(onFetchEvent(null))
                    resolve(false);
                }
            }


        })
    }
}

function onFetchClients(data) {

    return {
        type: 'FETCH_CLIENTS',
        data: data,
        receivedAt: Date.now()
    }
}


export function fetchClientAndEvent(clientId, eventId) {

    return function (dispatch) {
        return new Promise(async (resolve, reject) => {

            // get client by stub
            const res = await fetch(API_PROTOCOL + API_ENDPOINT + API_STAGE + "geteventandclient?clientId=" + encodeURIComponent(clientId) + "&eventId=" + encodeURIComponent(eventId))
            if (!res || !res.ok) {
                console.log("error with fetchClientAndEvent", res)
                dispatch(onFetchClientAndEvent(null))
                resolve(false);
            } else {
                console.log("res", res)

                const data = await res.json()
                if (data && data.hasEvents) {
                    dispatch(onFetchClientAndEvent(data))
                    resolve(data);
                } else {
                    dispatch(onFetchClientAndEvent(null))
                    resolve(false);
                }
            }


        })
    }
}

function onFetchClientAndEvent(data) {

    return {
        type: 'FETCH_CLIENT_AND_EVENT',
        data: data,
        receivedAt: Date.now()
    }
}

export function fetchClients() {
    return function (dispatch) {
        return new Promise(async (resolve, reject) => {

            // get client by stub
            const res = await fetch(API_PROTOCOL + API_ENDPOINT + API_STAGE + "getclients")
            if (!res || !res.ok) {
                console.log("error with fetchEvent", res)
                dispatch(onFetchClients(null))
                resolve(false);
            } else {
                console.log("res", res)

                const data = await res.json()
                if (data) {
                    dispatch(onFetchClients(data))
                    resolve(true);
                } else {
                    dispatch(onFetchClients(null))
                    resolve(false);
                }
            }


        })
    }
}

function onFetchPastEvents(data) {

    return {
        type: 'FETCH_PAST_EVENTS',
        data: data,
        receivedAt: Date.now()
    }
}

export function fetchPastEvents(clientId) {
    return function (dispatch) {
        return new Promise(async (resolve, reject) => {

            // get client by stub
            const res = await fetch(API_PROTOCOL + API_ENDPOINT + API_STAGE + "getclientevents?clientId=" + encodeURIComponent(clientId) + "&includeClient=true")
            if (!res || !res.ok) {
                console.log("error with fetchPastEvent", res)
                dispatch(onFetchPastEvents(null))
                resolve(false);
            } else {
                console.log("res", res)

                const data = await res.json()
                if (data) {
                    dispatch(onFetchPastEvents(data))
                    resolve(true);
                } else {
                    dispatch(onFetchPastEvents(null))
                    resolve(false);
                }
            }


        })
    }
}

function onFetchEventReport(data) {

    return {
        type: 'FETCH_EVENT_REPORT',
        data: data,
        receivedAt: Date.now()
    }
}

export function fetchEventReport(clientId, eventId) {
    return function (dispatch) {
        return new Promise(async (resolve, reject) => {
            var options = {
                method: "GET",
                //service: "execute-api",
                region: API_REGION,
                host: API_ENDPOINT,
                path: API_STAGE + "geteventreport?clientId=" + encodeURIComponent(clientId) + "&eventId=" + encodeURIComponent(eventId),
                url: "https://" + API_ENDPOINT + API_STAGE + "geteventreport?clientId=" + encodeURIComponent(clientId) + "&eventId=" + encodeURIComponent(eventId),
            }
            const credentials = await Auth.currentCredentials();
            const { accessKeyId, secretAccessKey, sessionToken } = credentials;
            const request = aws4.sign(options, {
                accessKeyId,
                secretAccessKey,
                sessionToken
            });
            delete request.headers.Host;
            console.log(credentials)
            console.log(request.headers)
            /* const res = await fetch(options.url, {
                headers: request.headers
            }) */
            let res
            try {
                res = await fetch(options.url, {
                    headers: request.headers
                })
            } catch (e) {
                console.log("Error fetching, ", e)
                window.open("/")
            }

            if (!res || !res.ok) {
                console.log("error with fetchEventReport", res)
                dispatch(onFetchEventReport(null))
                resolve(false);
            } else {
                console.log("res", res)

                const data = await res.json()
                if (data) {
                    dispatch(onFetchEventReport(data))
                    resolve(data);
                } else {
                    dispatch(onFetchEventReport(null))
                    resolve(false);
                }
            }


        })
    }
}

export function generatePDF(data) {
    console.log(data)
    return function (dispatch) {
        return new Promise(async (resolve, reject) => {

            let res = await fetch("https://o18jzj3fw8.execute-api.ap-southeast-2.amazonaws.com/Prod/snap", {
                method: "POST",
                body: JSON.stringify({
                    path: data,
                    pdf: "1"
                })
            })
            if (!res || !res.ok) {
                console.log("error with generatePDF", res)
                resolve(false);
            } else {
                console.log("res", res)

                const data = await res.json()
                if (data) {
                    resolve(data);
                } else {
                    resolve(false);
                }
            }


        })
    }
}

function onUpdateMaxPrizePoolAndDrawDate(data) {

    return {
        type: 'UPDATE_MAX_PRIZE_POOL_AND_DRAW_DATE',
        data: data,
        receivedAt: Date.now()
    }
}

export function updateMaxPrizePoolAndDrawDate(data) {
    return function (dispatch) {
        return new Promise(async (resolve, reject) => {
            var options = {
                method: "POST",
                service: "execute-api",
                body: JSON.stringify(data),
                region: API_REGION,
                host: API_ENDPOINT,
                path: API_STAGE + "updateMaxPrizePoolAndDrawDate",
                url: "https://" + API_ENDPOINT + API_STAGE + "updateMaxPrizePoolAndDrawDate",
            }
            const credentials = await Auth.currentCredentials();
            const { accessKeyId, secretAccessKey, sessionToken } = credentials;
            const request = aws4.sign(options, {
                accessKeyId,
                secretAccessKey,
                sessionToken
            });
            delete request.headers.Host;
            console.log(credentials)
            console.log(request.headers)
            /*  const res = await fetch(options.url, {
                 headers: request.headers,
                 method:"POST",
                 body:JSON.stringify(data)
             }) */
            let res
            try {
                res = await fetch(options.url, {
                    headers: request.headers,
                    method: "POST",
                    body: JSON.stringify(data)
                })
            } catch (e) {
                console.log("Error fetching, ", e)
                window.open("/")
            }

            if (!res || !res.ok) {
                console.log("error with updateMaxPrizePoolAndDrawDate", res)
                dispatch(onFetchEventReport(null))
                resolve(false);
            } else {
                console.log("res", res)

                const data = await res.json()
                if (data) {
                    dispatch(onUpdateMaxPrizePoolAndDrawDate(data))
                    resolve(data);
                } else {
                    dispatch(onUpdateMaxPrizePoolAndDrawDate(null))
                    resolve(false);
                }
            }


        })
    }
}

function oManualDraw(data) {

    return {
        type: 'MANUAL_DRAW',
        data: data,
        receivedAt: Date.now()
    }
}

export function manualDraw(data) {
    return function (dispatch) {
        return new Promise(async (resolve, reject) => {
            var options = {
                method: "POST",
                service: "execute-api",
                body: JSON.stringify(data),
                region: API_REGION,
                host: API_ENDPOINT,
                path: API_STAGE + "manualDraw",
                url: "https://" + API_ENDPOINT + API_STAGE + "manualDraw",
            }
            const credentials = await Auth.currentCredentials();
            const { accessKeyId, secretAccessKey, sessionToken } = credentials;
            const request = aws4.sign(options, {
                accessKeyId,
                secretAccessKey,
                sessionToken
            });
            delete request.headers.Host;
            console.log(credentials)
            console.log(request.headers)
            /* const res = await fetch(options.url, {
                headers: request.headers,
                method: "POST",
                body: JSON.stringify(data)
            }) */
            let res
            try {
                res = await fetch(options.url, {
                    headers: request.headers,
                    method: "POST",
                    body: JSON.stringify(data)
                })
            } catch (e) {
                console.log("Error fetching, ", e)
                window.open("/")
            }

            if (!res || !res.ok) {
                console.log("error with manualDraw", res)
                dispatch(onFetchEventReport(null))
                resolve(false);
            } else {
                console.log("res", res)

                const data = await res.json()
                if (data) {
                    dispatch(oManualDraw(data))
                    resolve(data);
                } else {
                    dispatch(oManualDraw(null))
                    resolve(false);
                }
            }


        })
    }
}

function onCreateNextEvent(data) {

    return {
        type: 'CREATE_NEXT_EVENT',
        data: data,
        receivedAt: Date.now()
    }
}

export function createNextEvent(data) {
    return function (dispatch) {
        return new Promise(async (resolve, reject) => {
            var options = {
                method: "POST",
                service: "execute-api",
                body: JSON.stringify(data),
                region: API_REGION,
                host: API_ENDPOINT,
                path: API_STAGE + "createNextEvent",
                url: "https://" + API_ENDPOINT + API_STAGE + "createNextEvent",
            }
            const credentials = await Auth.currentCredentials();
            const { accessKeyId, secretAccessKey, sessionToken } = credentials;
            const request = aws4.sign(options, {
                accessKeyId,
                secretAccessKey,
                sessionToken
            });
            delete request.headers.Host;
            console.log(credentials)
            console.log(request.headers)
            /* const res = await fetch(options.url, {
                headers: request.headers,
                method: "POST",
                body: JSON.stringify(data)
            }) */
            let res
            try {
                res = await fetch(options.url, {
                    headers: request.headers,
                    method: "POST",
                    body: JSON.stringify(data)
                })
            } catch (e) {
                console.log("Error fetching, ", e)
                window.open("/")
            }

            if (!res || !res.ok) {
                console.log("error with createNextEvent", res)
                dispatch(onFetchEventReport(null))
                resolve(false);
            } else {
                console.log("res", res)

                const data = await res.json()
                if (data) {
                    dispatch(onCreateNextEvent(data))
                    resolve(data);
                } else {
                    dispatch(onCreateNextEvent(null))
                    resolve(false);
                }
            }


        })
    }
}

export function purchase(data) {
    return function (dispatch) {
        return new Promise(async (resolve, reject) => {
            console.log("purchase data", data)
            // get client by stub
            const res = await fetch(API_PROTOCOL + API_ENDPOINT + API_STAGE + "buytickets", {
                method: "POST",
                body: JSON.stringify({
                    clientId: data.clientId,
                    eventId: data.eventId,
                    ticketCount: data.ticketCount,
                    phone: data.phone,
                    cardName: data.cardName,
                    cardNumber: data.cardNumber,
                    expiryMonth: data.expiryMonth,
                    expiryYear: data.expiryYear,
                    cvc: data.cvc,
                    email: data.email,
                    optIn: data.optIn
                })
            })
            if (!res || !res.ok) {
                console.log("error with purchase", res)
                resolve({ ok: false, message: "There was an error processing your purchase. Please try again." });
            } else {
                console.log("res", res)

                const data = await res.json()
                if (data) {
                    resolve(data);
                } else {
                    resolve({ ok: false, message: "There was an error processing your purchase. Please try again." });
                }
            }


        })
    }
}

function onGetEventPurchases(data) {

    return {
        type: 'GET_EVENT_PURCHASES',
        data: data,
        receivedAt: Date.now()
    }
}

export function getEventPurchases(eventId, phone) {
    return function (dispatch) {
        return new Promise(async (resolve, reject) => {

            // get client by stub
            const res = await fetch(API_PROTOCOL + API_ENDPOINT + API_STAGE + "geteventpurchases?eventId=" + encodeURIComponent(eventId) + "&phone=" + encodeURIComponent(phone))
            if (!res || !res.ok) {
                console.log("error with getEventPurchases", res)
                dispatch(onGetEventPurchases(null))
                resolve([]);
            } else {
                console.log("res", res)

                const data = await res.json()
                if (data) {
                    dispatch(onGetEventPurchases(data))
                    resolve(data);
                } else {
                    dispatch(onGetEventPurchases(null))
                    resolve([]);
                }
            }


        })
    }
}