import React, { Component } from 'react'
import {
    withRouter,
    Redirect
} from 'react-router-dom'
import { Container, Row, Col, Button, ProgressBar } from 'react-bootstrap'
import 'babel-polyfill';
import _ from 'lodash'

import Page from './Page'
import '../assets/libs/custombox/custombox.min.css'
import '../assets/css/app.css'
import '../assets/css/icons.css'

class Header extends Page {

    state = {
        busy: false,
        checkingUser: true
    }

    componentDidMount() {
        let self = this

    }


    render() {
        let self = this
        let user = !this.props.user ? null : <li className="dropdown notification-list">
            <a onClick={() => self.props.logout()} className="nav-link nav-user mr-0 waves-effect waves-light">
                <span className="pro-user-name ml-1">
                    Logout
            </span>
            </a>
        </li>

        return (
            <header id="topnav">

                <div className="navbar-custom">
                    <div className="container-fluid">
                        <ul className="list-unstyled topnav-menu float-right mb-0">
                            {user}
                        </ul>
                        <div className="logo-box">
                            <a href="/clients" className="logo text-center">
                                <img src="/images/logo.png" alt="" height="30" />
                            </a>
                        </div>
                    </div>
                </div>


            </header>
        )
    }
}

export default withRouter(Header)
