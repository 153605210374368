import { connect } from 'react-redux'
import Events from '../components/Events'
import * as api from '../store/api'

const mapStateToProps = (state) => {
  return {
    events:state.service.events,
    client:state.service.client,
    user:state.service.user
  };
}


const mapDispatchToProps = (dispatch) => ({
  checkUserSession: (callback) => { dispatch(api.checkUserSession()).then(callback) },
  fetchEvents: (clientId, callback) => {
    dispatch(api.fetchPastEvents(clientId)).then(callback)
  },
  createNextEvent: (data, callback) => {
    dispatch(api.createNextEvent(data)).then(callback)
  }
})


export default connect(mapStateToProps, mapDispatchToProps)(Events)
