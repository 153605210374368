import { connect } from 'react-redux'
import Report from '../components/Report'
import * as api from '../store/api'

const mapStateToProps = (state) => {
  return {
    user:state.service.user
  };
}


const mapDispatchToProps = (dispatch) => ({
  checkUserSession: (callback) => { dispatch(api.checkUserSession()).then(callback) },
  fetchEventReport: (clientId, eventId, callback) => {
    dispatch(api.fetchEventReport(clientId, eventId)).then(callback)
  },
  generatePDF: (data, callback) => {
    dispatch(api.generatePDF(data)).then(callback)
  }
})


export default connect(mapStateToProps, mapDispatchToProps)(Report)
