import React, { Component } from 'react'
import {
    withRouter,
    Redirect
} from 'react-router-dom'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
import 'babel-polyfill';
import _, { first } from 'lodash'
import { Doughnut, Line } from 'react-chartjs-2';
import moment from 'moment-timezone'
import Page from './Page'
import '../styles/global.css'
import NumberHelpers from '../helpers/NumberHelpers'
import Header from '../containers/HeaderContainer';

const bundleSaleColours = [
    "red",
    "blue",
    "green",
    "orange",
    "yellow",
    "pink",
    "purple",
    "black"
]

class Report extends Page {

    state = {
        busy: false,
        data: null,
        checkingUser: true,
        attendance: "",
        winnersName: "",
        rate: ""
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        let self = this
        this._fetchReport = this._fetchReport.bind(this)
        this._generatePDF = this._generatePDF.bind(this)
        if (!this.props.user) {
            let self = this
            this.setState({ checkingUser: true }, () => {
                self.props.checkUserSession(() => {
                    self.setState({ checkingUser: false })
                    self._fetchReport()
                })
            })
        } else {
            self.setState({ checkingUser: false }, () => {
                self._fetchReport()
            })

        }


    }

    _fetchReport() {
        let self = this
        let pathArr = self.props.location.pathname.split("/")
        let clientId = pathArr[pathArr.length - 2]
        let eventId = pathArr[pathArr.length - 1]
        self.setState({ busy: true, eventId: eventId }, () => {
            self.props.fetchEventReport(clientId, eventId, (data) => {
                self.setState({ busy: false, data: data })
            })
        })
    }

    _generatePDF() {
        let self = this

        let salesData = []
        let salesLabels = []
        let bundleSaleAmounts = []
        let bundleSaleLabels = []
        let bundleSalesDict = {}
        let purchasesDict = {}
        let winner = {}

        let firstTicketSold, lastTicketSold

        if (this.state.data.purchases) {
            for (let i = 0; i < this.state.data.purchases.length; i++) {
                let asDate = new Date(this.state.data.purchases[i].dateCreated)
                if(!firstTicketSold || asDate < firstTicketSold) firstTicketSold = asDate
                if(!lastTicketSold || asDate > lastTicketSold) lastTicketSold = asDate
                for (let j = 0; j < this.state.data.purchases[i].ticketCount; j++) {
                    if (this.state.data.purchases[i].ticketIndex + j == this.state.data.event.winningTicket) winner.phone = this.state.data.purchases[i].phone

                }
                if (bundleSalesDict[this.state.data.purchases[i].cost]) {
                    bundleSalesDict[this.state.data.purchases[i].cost] += 1;
                } else {
                    bundleSalesDict[this.state.data.purchases[i].cost] = 1;
                }


                var m = moment(new Date(self.state.data.purchases[i].dateCreated));
                var roundDown = m.startOf('date');
                if (purchasesDict[roundDown]) {
                    purchasesDict[roundDown] += self.state.data.purchases[i].cost;
                } else {
                    purchasesDict[roundDown] = self.state.data.purchases[i].cost;
                }
            }
        }
        for (var b in bundleSalesDict) {
            bundleSaleLabels.push(bundleSalesDict[b] + " x $" + b)
            bundleSaleAmounts.push(bundleSalesDict[b])
        }
        console.log("purchasesDict", purchasesDict)
        let purchasesArr = []

        for (var b in purchasesDict) {
            purchasesArr.push({
                x: b,
                y: purchasesDict[b]
            })
        }
        purchasesArr.sort(function (b, a) {
            a = new Date(a.x);
            b = new Date(b.x);
            return a > b ? -1 : a < b ? 1 : 0;
        });
        for (let i = 0; i < purchasesArr.length; i++) {
            salesData.push(purchasesArr[i])
            salesLabels.push(moment(purchasesArr[i].x).tz("Australia/Adelaide").format("DD MMM"))
        }
        //return

        let data = {
            event: self.state.data.event,
            client: self.state.data.client,
            attendance: self.state.attendance,
            winnersName: self.state.winnersName,
            rate: self.state.rate / 100,
            salesData: salesData,
            salesLabels: salesLabels,
            bundleSaleAmounts: bundleSaleAmounts,
            bundleSaleLabels: bundleSaleLabels,
            winner: winner,
            firstTicketSold:firstTicketSold,
            lastTicketSold:lastTicketSold
        }
        console.log(data)
        let base64 = btoa(JSON.stringify(data))
        let path = "https://admin.s50.events/pdf/?data=" + base64
        
        
        self.setState({ generatingPdf: true }, () => {
            self.props.generatePDF(path, (res) => {
                console.log(res)
                if (res.url) {
                    var element = document.createElement('a');
                    element.setAttribute('href', res.url);
                    element.setAttribute('download', self.state.eventId + ".pdf");
                    element.setAttribute('target', "_blank");

                    element.style.display = 'none';
                    document.body.appendChild(element);

                    element.click();

                    document.body.removeChild(element);
                }
                self.setState({ generatingPdf: false })
            })
        })


    }

    render() {
        let self = this

        if (self.state.checkingUser || self.state.busy) {
            return (
                <div className="wrapper">
                    <div className="container-fluid">
                        <div className="text-center v-margin-20">
                            <img src="/images/loaders/oval.svg" />
                        </div>
                    </div>
                </div>
            )
        }

        if (!this.props.user) {
            return <Redirect to='/' />
        }

        if (!self.state.data || !self.state.data.event) {
            return (
                <Container>
                    <div className="text-center v-margin-20">
                        Event not found.
                    </div>
                </Container>
            )
        }

        let csvArr = [["eventId", "ticketId", "datePurchased", "transactionId", "phone", "email", "optIn", "winner"]]
        let winner = this.state.data.event.completed ? {} : null
        let salesData = []
        let salesLabels = []
        let bundleSaleAmounts = []
        let bundleSaleLabels = []
        let bundleSalesDict = {}
        let purchasesDict = {}


        var m1 = moment(new Date(self.state.data.event.startDate));
        var roundDown1 = m1.startOf('date');
        var m2 = moment(self.state.data.event.completed ? new Date(self.state.data.event.endDate) : new Date());
        var roundDown2 = m2.startOf('date');
        var a = moment(roundDown1);
        var b = moment(roundDown2);
        let diff = Math.abs(a.diff(b, 'days'))
        for (let k = 0; k < diff; k++) {
            let n = moment(a);
            n.add(k, "days")
            purchasesDict[n] = 0
        }


        if (this.state.data.purchases) {
            for (let i = 0; i < this.state.data.purchases.length; i++) {
                for (let j = 0; j < this.state.data.purchases[i].ticketCount; j++) {
                    let arr = []
                    arr.push(this.state.data.event.SK)
                    arr.push(this.state.data.event.SK + "_" + NumberHelpers.pad(this.state.data.purchases[i].ticketIndex + j, 3))
                    arr.push(this.state.data.purchases[i].dateCreated)
                    arr.push(this.state.data.purchases[i].transactionId)
                    arr.push(this.state.data.purchases[i].phone)
                    arr.push(this.state.data.purchases[i].email)
                    arr.push(this.state.data.purchases[i].optIn)
                    arr.push(this.state.data.purchases[i].ticketIndex + j == this.state.data.event.winningTicket)
                    if (this.state.data.purchases[i].ticketIndex + j == this.state.data.event.winningTicket) winner.phone = this.state.data.purchases[i].phone

                    csvArr.push(arr)

                }
                if (bundleSalesDict[this.state.data.purchases[i].cost]) {
                    bundleSalesDict[this.state.data.purchases[i].cost] += 1;
                } else {
                    bundleSalesDict[this.state.data.purchases[i].cost] = 1;
                }


                var m = moment(new Date(self.state.data.purchases[i].dateCreated));
                var roundDown = m.startOf('date');
                if (purchasesDict[roundDown]) {
                    purchasesDict[roundDown] += self.state.data.purchases[i].cost;
                } else {
                    purchasesDict[roundDown] = self.state.data.purchases[i].cost;
                }
            }
        }
        for (var b in bundleSalesDict) {
            bundleSaleLabels.push(bundleSalesDict[b] + " x $" + b)
            bundleSaleAmounts.push(bundleSalesDict[b])
        }
        console.log("purchasesDict", purchasesDict)
        let purchasesArr = []

        for (var b in purchasesDict) {
            purchasesArr.push({
                x: b,
                y: purchasesDict[b]
            })
        }
        purchasesArr.sort(function (b, a) {
            a = new Date(a.x);
            b = new Date(b.x);
            return a > b ? -1 : a < b ? 1 : 0;
        });
        for (let i = 0; i < purchasesArr.length; i++) {
            salesData.push(purchasesArr[i])
            salesLabels.push(moment(purchasesArr[i].x).tz("Australia/Adelaide").format("DD MMM"))
        }


        let csvContent = "data:text/csv;charset=utf-8,";
        csvArr.forEach(function (rowArray) {
            let row = rowArray.join(",");
            csvContent += row + "\r\n";
        });
        let csv = encodeURI(csvContent);

        let widgets = []
        if (!self.state.data.event.completed) {
            widgets.push(
                <Row>
                    <Col md={4}>
                        <div class="widget-simple text-center card-box">
                            <h3 class="text-success counter mt-0">${self.state.data.event.prizePool}</h3>
                            <p class="text-muted mb-0">Prize Pool</p>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div class="widget-simple text-center card-box">
                            <h3 class="text-success counter mt-0">{self.state.data.event.ticketCount}</h3>
                            <p class="text-muted mb-0">Tickets Sold</p>
                        </div>
                    </Col>
                </Row>
            )
        } else {
            widgets.push(
                <Row>
                    <Col md={4}>
                        <div class="widget-simple text-center card-box">
                            <h3 class="text-success counter mt-0">${self.state.data.event.prizePool}</h3>
                            <p class="text-muted mb-0">Prize Pool</p>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div class="widget-simple text-center card-box">
                            <h3 class="text-success counter mt-0">{self.state.data.event.ticketCount}</h3>
                            <p class="text-muted mb-0">Tickets Sold</p>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div class="widget-simple text-center card-box">
                            <h3 class="text-success counter mt-0">{self.state.data.event.SK + "_" + NumberHelpers.pad(self.state.data.event.winningTicket, 3)}</h3>
                            <p class="text-muted mb-0">Winning Ticket</p>
                        </div>
                    </Col>

                </Row>
            )
            widgets.push(
                <Row>
                    <Col md={4}>
                        <div class="widget-simple text-center card-box">
                            <h3 class="text-success counter mt-0">{winner.phone}</h3>
                            <p class="text-muted mb-0">Winning Phone Number</p>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div class="widget-simple text-center card-box">
                            <h3 class="text-success counter mt-0">{moment(self.state.data.event.endDate).tz("Australia/Adelaide").format("DD/MM/YYYY h:mmA")}</h3>
                            <p class="text-muted mb-0">Draw Date</p>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div class="widget-simple text-center card-box">
                            <Button href={csv} download={self.state.data.event.SK} variant="warning">Download CSV</Button>

                        </div>
                    </Col>
                </Row>
            )
        }
        widgets.push(
            <Row>
                <Col md={12}>
                    <div class="widget-simple text-center card-box">
                        <Row>
                            <Col md={3}>
                                <Form.Control disabled={self.state.generatingPdf} type="number" placeholder="Client Rate (%)" value={self.state.rate} onChange={(e) => {
                                    self.setState({ rate: e.target.value })
                                }} />
                            </Col>
                            <Col md={3}>
                                <Form.Control disabled={self.state.generatingPdf} type="number" placeholder="Attendance" value={self.state.attendance} onChange={(e) => {
                                    self.setState({ attendance: e.target.value })
                                }} />
                            </Col>
                            <Col md={3}>
                                <Form.Control disabled={self.state.generatingPdf} type="text" placeholder="Winner's Name" value={self.state.winnersName} onChange={(e) => {
                                    self.setState({ winnersName: e.target.value })
                                }} />
                            </Col>
                            <Col md={3}>
                                <Button disabled={self.state.generatingPdf || self.state.rate == "" || self.state.attendance == "" || self.state.winnersName == ""} onClick={self._generatePDF} download={self.state.data.event.SK} variant="info">{self.state.generatingPdf ? "Generating..." : "Generate Report"}</Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        )
        let eventLink = "/events/" + self.state.data.event.PK
        let content =
            <div className="wrapper">
                <div className="container-fluid">
                    <a href="/">Dashboard</a> > <a href={eventLink}>{self.state.data.client.name}</a> > Report ({self.state.data.event.SK})
                    {widgets}
                    <Row>
                        <Col md={6}>
                            <Line data={{
                                labels: salesLabels,
                                datasets: [{
                                    label: "Sales",
                                    data: salesData
                                }
                                ]
                                
                            }}
                                options={
                                    {
                                        scales: {
                                            yAxes: [{
                                                ticks: {
                                                    beginAtZero: true,
                                                }
                                            }]
                                        },
                                    }
                                }
                            />
                        </Col>
                        <Col md={6}>
                            <Doughnut data={{
                                datasets: [{
                                    data: bundleSaleAmounts,
                                    backgroundColor: bundleSaleColours
                                }],


                                // These labels appear in the legend and in the tooltips when hovering different arcs
                                labels: bundleSaleLabels
                            }}
                            />
                        </Col>
                    </Row>
                </div>
            </div>


        return (
            <div>
                <Header />
                {content}
            </div>
        )
    }
}

export default withRouter(Report)        